.feature-card1-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  border-radius: 12px;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.feature-card1-icon {
  fill: #595959;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: 24px;
}
.feature-card1-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card1-text {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 1200px) {
  .feature-card1-icon {
    display: none;
  }
}
@media(max-width: 767px) {
  .feature-card1-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card1-container {
    flex-direction: column;
  }
}
