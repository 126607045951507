.header-container-header-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.header-container-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 90%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-container-container {
  gap: 2rem;
  flex: 0 0 auto;
  width: 458px;
  height: 33px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header-container-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-container-image {
  width: 158px;
  object-fit: cover;
}
.header-container-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.header-container-text {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.header-container-text:hover {
  color: var(--dl-color-branding-yaleblue);
}
.header-container-text1 {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.header-container-text1:hover {
  color: var(--dl-color-branding-yaleblue);
}
.header-container-text2 {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.header-container-text2:hover {
  color: var(--dl-color-branding-yaleblue);
}
.header-container-container1 {
  width: 333px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header-container-text3 {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.header-container-text3:hover {
  color: var(--dl-color-branding-yaleblue);
}
.header-container-link {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.header-container-link:hover {
  color: var(--dl-color-turquoise-default);
}
.header-container-link1 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  border-color: var(--dl-color-gray-white);
  text-decoration: none;
  background-color: transparent;
}
.header-container-link1:hover {
  color: var(--dl-color-gray-900);
  background-color: var(--dl-color-gray-white);
}
.header-container-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-container-menu1 {
  color: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
}
.header-container-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.header-container-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.header-container-logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-container-image1 {
  width: 100px;
  object-fit: cover;
}
.header-container-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-container-icon03 {
  width: 24px;
  height: 24px;
}
.header-container-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-container-text4 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-container-text4:hover {
  color: var(--dl-color-turquoise-default);
}
.header-container-text5 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-container-text5:hover {
  color: var(--dl-color-turquoise-default);
}
.header-container-text6 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-container-text6:hover {
  color: var(--dl-color-turquoise-default);
}
.header-container-text7 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-container-text7:hover {
  color: var(--dl-color-turquoise-default);
}
.header-container-text8 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-container-text8:hover {
  color: var(--dl-color-turquoise-default);
}
.header-container-bot {
  flex: 0 0 auto;
  display: none;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.header-container-container2 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.header-container-link2 {
  display: contents;
}
.header-container-container3 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.header-container-icon05 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.header-container-icon05:hover {
  fill: var(--dl-color-purple-default);
}
.header-container-link3 {
  display: contents;
}
.header-container-container4 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.header-container-icon07 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.header-container-icon07:hover {
  fill: var(--dl-color-pink-default);
}
.header-container-link4 {
  display: contents;
}
.header-container-container5 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.header-container-icon09 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.header-container-icon09:hover {
  fill: var(--dl-color-turquoise-default);
}
.header-container-link5 {
  display: contents;
}
.header-container-container6 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.header-container-icon11 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.header-container-icon11:hover {
  fill: var(--dl-color-orange-default);
}

@media(max-width: 1600px) {
  .header-container-header {
    max-width: 90%;
  }
  .header-container-container1 {
    width: 313px;
  }
}
@media(max-width: 1200px) {
  .header-container-header {
    max-width: 1110px;
  }
  .header-container-container1 {
    width: 313px;
  }
}
@media(max-width: 991px) {
  .header-container-container1 {
    width: 295px;
  }
  .header-container-link1 {
    color: var(--dl-color-gray-white);
    border-color: var(--dl-color-gray-white);
    background-color: transparent;
  }
}
@media(max-width: 767px) {
  .header-container-menu {
    display: none;
  }
  .header-container-container1 {
    display: none;
  }
  .header-container-burger-menu {
    display: block;
  }
}
@media(max-width: 479px) {
  .header-container-container {
    width: 189px;
  }
  .header-container-mid {
    align-items: center;
  }
  .header-container-text4 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-container-text5 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-container-text6 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-container-text7 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-container-text8 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-container-button {
    color: var(--dl-color-branding-aliceblue);
    border-color: var(--dl-color-branding-yaleblue);
    background-color: var(--dl-color-branding-yaleblue);
  }
  .header-container-bot {
    width: 100%;
  }
}
