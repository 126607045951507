.footer-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.footer-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 90%;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.footer-image {
  max-width: 200px;
  object-fit: cover;
}
.footer-container1 {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-start;
}
.footer-text {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  cursor: pointer;
}
.footer-text:hover {
  color: var(--dl-color-branding-yaleblue);
}
.footer-text1 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  cursor: pointer;
}
.footer-text1:hover {
  color: var(--dl-color-branding-yaleblue);
}
.footer-text2 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  cursor: pointer;
}
.footer-text2:hover {
  color: var(--dl-color-branding-yaleblue);
}
.footer-text3 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  cursor: pointer;
}
.footer-text3:hover {
  color: var(--dl-color-branding-yaleblue);
}
.footer-divider {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--dl-color-gray-white);
}
.footer-container2 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer-text4 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}
.footer-container3 {
  flex: 0 0 auto;
  width: 200px;
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.footer-container4 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.footer-icon {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.footer-icon:hover {
  fill: var(--dl-color-purple-default);
}
.footer-container5 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.footer-icon2 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.footer-icon2:hover {
  fill: var(--dl-color-pink-default);
}
.footer-container6 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.footer-icon4 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.footer-icon4:hover {
  fill: var(--dl-color-branding-yaleblue);
}
.footer-container7 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.footer-icon6 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.footer-icon6:hover {
  fill: var(--dl-color-orange-default);
}
@media(max-width: 1200px) {
  .footer-container {
    max-width: 1110px;
  }
}
@media(max-width: 767px) {
  .footer-container1 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-container2 {
    flex-direction: column;
  }
  .footer-text4 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
