.how-to-create-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 350px;
  align-items: flex-start;
  flex-direction: column;
}
.how-to-create-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.how-to-create-image {
  width: auto;
  height: auto;
  object-fit: cover;
}
.how-to-create-container2 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.how-to-create-text {
  font-size: 1.45em;
  margin-bottom: var(--dl-space-space-unit);
}
.how-to-create-text1 {
  font-size: 1.1rem;
  margin-bottom: 0px;
}























@media(max-width: 1600px) {
  .how-to-create-container1 {
    justify-content: flex-start;
  }
  .how-to-create-root-class-name {
    max-width: 45%;
  }
  .how-to-create-root-class-name1 {
    max-width: 45%;
  }
  .how-to-create-root-class-name2 {
    max-width: 45%;
  }
  .how-to-create-root-class-name3 {
    max-width: 45%;
  }
  .how-to-create-root-class-name4 {
    max-width: 45%;
  }
  .how-to-create-root-class-name5 {
    max-width: 45%;
  }
}
@media(max-width: 1200px) {
  .how-to-create-container1 {
    justify-content: flex-start;
  }
  .how-to-create-container2 {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .how-to-create-root-class-name {
    max-width: 290px;
  }
  .how-to-create-root-class-name1 {
    max-width: 290px;
  }
  .how-to-create-root-class-name2 {
    max-width: 290px;
  }
  .how-to-create-root-class-name3 {
    max-width: 290px;
  }
  .how-to-create-root-class-name4 {
    max-width: 290px;
  }
  .how-to-create-root-class-name5 {
    max-width: 290px;
  }
  .how-to-create-root-class-name6 {
    max-width: 300px;
  }
  .how-to-create-root-class-name7 {
    max-width: 300px;
  }
  .how-to-create-root-class-name8 {
    max-width: 300px;
  }
  .how-to-create-root-class-name9 {
    max-width: 300px;
  }
  .how-to-create-root-class-name10 {
    max-width: 300px;
  }
  .how-to-create-root-class-name11 {
    max-width: 300px;
  }
  .how-to-create-root-class-name12 {
    max-width: 300px;
  }
  .how-to-create-root-class-name13 {
    max-width: 300px;
  }
  .how-to-create-root-class-name14 {
    max-width: 300px;
  }
  .how-to-create-root-class-name15 {
    max-width: 300px;
  }
  .how-to-create-root-class-name16 {
    max-width: 300px;
  }
}
@media(max-width: 991px) {
  .how-to-create-container1 {
    justify-content: center;
  }
  .how-to-create-container2 {
    align-items: center;
    justify-content: flex-start;
  }
  .how-to-create-text1 {
    text-align: center;
  }
  .how-to-create-root-class-name {
    max-width: 45%;
  }
  .how-to-create-root-class-name1 {
    max-width: 45%;
  }
  .how-to-create-root-class-name2 {
    max-width: 45%;
  }
  .how-to-create-root-class-name3 {
    max-width: 45%;
  }
  .how-to-create-root-class-name4 {
    max-width: 45%;
  }
  .how-to-create-root-class-name5 {
    max-width: 45%;
  }
}
@media(max-width: 767px) {
  .how-to-create-container1 {
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .how-to-create-container1 {
    justify-content: center;
  }
  .how-to-create-root-class-name {
    max-width: 100%;
  }
  .how-to-create-root-class-name1 {
    max-width: 100%;
  }
  .how-to-create-root-class-name2 {
    max-width: 100%;
  }
  .how-to-create-root-class-name3 {
    max-width: 100%;
  }
  .how-to-create-root-class-name4 {
    max-width: 100%;
  }
  .how-to-create-root-class-name5 {
    max-width: 100%;
  }
}
