.home-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 90%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container01 {
  gap: 2rem;
  flex: 0 0 auto;
  width: 458px;
  height: 33px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 158px;
  object-fit: cover;
}
.home-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  cursor: pointer;
}
.home-text:hover {
  color: var(--dl-color-branding-yaleblue);
}
a.home-button8.button:hover {
  color: var(--dl-color-branding-aliceblue) !important;
  background-color: var(--dl-color-branding-brightblue);
}
.home-text001 {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  cursor: pointer;
}
.home-text001:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-text002 {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  cursor: pointer;
}
.home-text002:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-container02 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-text003 {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  cursor: pointer;
}
.home-text003:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-link {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.home-link:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-link01 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  border-color: var(--dl-color-gray-white);
  text-decoration: none;
  background-color: transparent;
}
.home-link01:hover {
  color: var(--dl-color-gray-900);
  background-color: var(--dl-color-gray-white);
}
.home-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-menu1 {
  color: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
}
.home-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  right: calc(100% * -1);
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: fixed;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-mid a{
  color: var(--bs-body-color);
}
.home-mid .home-button {
  color: white;
}
.home-text188,.home-text189,.home-text190 {
  color: var(--dl-color-gray-white) !important;
  text-decoration: none !important;
}.home-text188:hover,.home-text189:hover,.home-text190:hover {
  color: var(--dl-color-branding-yaleblue) !important;
}
.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.home-logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image01 {
  width: 100px;
  object-fit: cover;
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon03 {
  width: 24px;
  height: 24px;
}
.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text004 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text004:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-text005 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text005:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-text006 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text006:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-text007 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text007:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-text008 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text008:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-bot {
  flex: 0 0 auto;
  display: none;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.home-container03 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link02 {
  display: contents;
}
.home-container04 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon05 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon05:hover {
  fill: var(--dl-color-purple-default);
}
.home-link03 {
  display: contents;
}
.home-container05 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon07 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon07:hover {
  fill: var(--dl-color-pink-default);
}
.home-link04 {
  display: contents;
}
.home-container06 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon09 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon09:hover {
  fill: var(--dl-color-branding-yaleblue);
}
.home-link05 {
  display: contents;
}
.home-container07 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon11 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon11:hover {
  fill: var(--dl-color-orange-default);
}
.home-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 70vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.home-hero {
  gap: 1rem;
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  z-index: 100;
  max-width: 80%;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: 0;
  justify-content: space-between;
}
.home-container08 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-text009 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text013 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container11 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-link06 {
  display: contents;
}
.home-component {
  text-decoration: none;
}
.home-text017 {
  color: var(--dl-color-gray-white);
}
.home-container12 {
  flex: 0 0 auto;
  width: auto;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.home-container13 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  flex-direction: column;
}
.home-image02 {
  width: 670px;
  height: auto;
  align-self: flex-end;
  object-fit: cover;
}
.home-turquoise-cirble {
  top: 439px;
  left: auto;
  right: 430px;
  width: 170px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle {
  top: -100px;
  left: auto;
  right: -26px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-left {
  top: 653px;
  left: 0px;
  right: auto;
  width: 420px;
  bottom: auto;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-right {
  top: 441px;
  left: auto;
  right: 0px;
  width: 612px;
  bottom: auto;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-clients {
  flex: 0 0 auto;
  width: 100%;
  display: none;
  z-index: 100;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-divider {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-image03 {
  width: 100px;
  object-fit: cover;
}
.home-image04 {
  width: 100px;
  object-fit: cover;
}
.home-image05 {
  width: 100px;
  object-fit: cover;
}
.home-image06 {
  width: 100px;
  object-fit: cover;
}
.home-image07 {
  width: 100px;
  object-fit: cover;
}
.home-divider1 {
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  bottom: 0px;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-card-types {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 80%;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: flex-start;
}
.home-container14 {
  gap: 2rem;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-container15 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text021 {
  font-size: 3rem;
}
.home-text022 {
  font-size: 2rem;
}
.home-container16 {
  gap: var(--dl-space-space-doubleunit);
  flex: 1;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container17 {
  gap: 3rem;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.home-apps {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-container18 {
  gap: 1rem;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container19 {
  flex: 1;
  width: 50%;
  height: 80vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text023 {
  color: var(--dl-color-gray-white);
  font-size: 2.5rem;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text029 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text031 {
  font-style: normal;
  font-weight: 700;
}
.home-text032 {
  color: var(--dl-color-gray-white);
  font-size: 20px;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text033 {
  color: var(--dl-color-gray-white);
}
.home-text035 {
  color: var(--dl-color-gray-white);
}
.home-container20 {
  flex: 1;
  width: 50%;
  height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image08 {
  width: auto;
  height: 90%;
  object-fit: contain;
}
.home-how-to {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 80%;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: flex-start;
}
.home-container21 {
  gap: 2rem;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-container22 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text036 {
  font-size: 3rem;
}
.home-text037 {
  font-size: 2rem;
}
.home-container23 {
  gap: var(--dl-space-space-doubleunit);
  flex: 1;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container24 {
  gap: 3rem;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.home-pricing {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-container25 {
  width: 80%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text038 {
  color: var(--dl-color-gray-white);
  width: 100%;
  text-align: left;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text039 {
  white-space: pre-wrap;
}
.home-container26 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  justify-self: flex-start;
  flex-direction: column;
}
.home-container27 {
  gap: 1rem;
  flex: 1;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.home-container28 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container29 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text040 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text041 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text042 {
  color: var(--dl-color-orange-800);
}
.home-text043 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-container31 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container32 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text049 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text050 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text051 {
  color: var(--dl-color-orange-800);
}
.home-text052 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-container34 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container35 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text062 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text063 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text064 {
  color: var(--dl-color-orange-800);
}
.home-text065 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-container37 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container38 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text075 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text076 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text077 {
  color: var(--dl-color-orange-800);
}
.home-text078 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-container40 {
  gap: 1rem;
  flex: 1;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  justify-content: space-around;
}
.home-container41 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container42 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-button1 {
  color: var(--dl-color-branding-aliceblue);
  width: 100%;
  align-self: center;
  text-align: center;
  transition: 0.3s;
  border-color: var(--dl-color-branding-yaleblue);
  background-color: var(--dl-color-branding-yaleblue);
}
.home-button1:hover {
  background-color: var(--dl-color-branding-brightblue);
  color: var(--dl-color-branding-aliceblue);
}

.home-container43 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container44 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-button2 {
  color: var(--dl-color-branding-aliceblue);
  width: 100%;
  align-self: center;
  text-align: center;
  border-color: var(--dl-color-branding-yaleblue);
  background-color: var(--dl-color-branding-yaleblue);
}
.home-button2:hover {
  background-color: var(--dl-color-branding-brightblue);
  color: var(--dl-color-branding-aliceblue);
}
.home-container45 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container46 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-button3 {
  color: var(--dl-color-branding-aliceblue);
  width: 100%;
  text-align: center;
  border-color: var(--dl-color-branding-yaleblue);
  background-color: var(--dl-color-branding-yaleblue);
}
.home-button3:hover {
  background-color: var(--dl-color-branding-brightblue);
  color: var(--dl-color-branding-aliceblue);
}
.home-container47 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container48 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-button4 {
  color: var(--dl-color-branding-aliceblue);
  width: 100%;
  text-align: center;
  border-color: var(--dl-color-branding-yaleblue);
  background-color: var(--dl-color-branding-yaleblue);
}
.home-button4:hover {
  background-color: var(--dl-color-branding-brightblue);
  color: var(--dl-color-branding-aliceblue);
}
.home-container49 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.home-container50 {
  gap: 1rem;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.home-container51 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container52 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text088 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text089 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text090 {
  color: var(--dl-color-orange-800);
}
.home-text091 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-container54 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container55 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text097 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text098 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text099 {
  color: var(--dl-color-orange-800);
}
.home-text100 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-container57 {
  gap: 1rem;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.home-container58 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container59 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-button5 {
  color: var(--dl-color-branding-aliceblue);
  width: 100%;
  align-self: center;
  text-align: center;
  border-color: var(--dl-color-branding-yaleblue);
  background-color: var(--dl-color-branding-yaleblue);
}
.home-container60 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container61 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-button6 {
  color: var(--dl-color-branding-aliceblue);
  width: 100%;
  align-self: center;
  text-align: center;
  border-color: var(--dl-color-branding-yaleblue);
  background-color: var(--dl-color-branding-yaleblue);
}
.home-container62 {
  gap: 1rem;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.home-container63 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container64 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text110 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text111 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text112 {
  color: var(--dl-color-orange-800);
}
.home-text113 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-container66 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container67 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text123 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text124 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text125 {
  color: var(--dl-color-orange-800);
}
.home-text126 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-container69 {
  gap: 1rem;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.home-container70 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container71 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-button7 {
  color: var(--dl-color-branding-aliceblue);
  width: 100%;
  align-self: center;
  text-align: center;
  border-color: var(--dl-color-branding-yaleblue);
  background-color: var(--dl-color-branding-yaleblue);
}
.home-container72 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container73 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-button8 {
  color: var(--dl-color-branding-aliceblue);
  width: 100%;
  align-self: center;
  text-align: center;
  border-color: var(--dl-color-branding-yaleblue);
  background-color: var(--dl-color-branding-yaleblue);
}
.home-container74 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.home-container75 {
  gap: 1rem;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.home-container76 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container77 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text139 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text140 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text141 {
  color: var(--dl-color-branding-yaleblue);
}
.home-text142 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-container79 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container80 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text148 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text149 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text150 {
  color: var(--dl-color-branding-yaleblue);
}
.home-text151 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-container82 {
  gap: 1rem;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.home-container83 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container84 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text161 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text162 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text163 {
  color: var(--dl-color-branding-yaleblue);
}
.home-text164 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-container86 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-container87 {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text174 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text175 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text176 {
  color: var(--dl-color-branding-yaleblue);
}
.home-text177 {
  color: var(--dl-color-gray-white);
  margin-bottom: 0px;
}
.home-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-container89 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 90%;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-image09 {
  max-width: 200px;
  object-fit: cover;
}
.home-container90 {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-start;
}
.home-text187 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  cursor: pointer;
}
.home-text187:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-text188 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  cursor: pointer;
}
.home-text188:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-text189 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  cursor: pointer;
}
.home-text189:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-text190 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  cursor: pointer;
}
.home-text190:hover {
  color: var(--dl-color-branding-yaleblue);
}
.home-divider2 {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--dl-color-gray-white);
}
.home-container91 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-text191 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}
.home-container92 {
  flex: 0 0 auto;
  width: 200px;
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.home-container93 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon13 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon13:hover {
  fill: var(--dl-color-purple-default);
}
.home-container94 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon15 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon15:hover {
  fill: var(--dl-color-pink-default);
}
.home-container95 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon17 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon17:hover {
  fill: var(--dl-color-branding-yaleblue);
}
.home-container96 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon19 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon19:hover {
  fill: var(--dl-color-orange-default);
}
@media(max-width: 1600px) {
  .home-header {
    max-width: 90%;
  }
  .home-image02 {
    width: 520px;
    height: auto;
  }
  .home-container17 {
    gap: 4rem;
  }
  .home-apps {
    height: auto;
  }
  .home-container19 {
    height: auto;
  }
  .home-container24 {
    gap: 4rem;
  }
}
@media(max-width: 1200px) {
  .home-header {
    max-width: 1110px;
  }
  .home-hero {
    max-width: 1110px;
    padding-left: var(--dl-space-space-unit);
  }
  .home-image02 {
    width: 480px;
    height: auto;
  }
  .home-card-types {
    max-width: 1110px;
  }
  .home-container14 {
    padding-left: var(--dl-space-space-unit);
  }
  .home-container18 {
    max-width: 1110px;
  }
  .home-how-to {
    max-width: 1110px;
  }
  .home-container21 {
    padding-left: var(--dl-space-space-unit);
  }
  .home-pricing {
    max-width: 1110px;
  }
  .home-container89 {
    max-width: 1110px;
  }
}
@media(max-width: 991px) {
  .home-button {
    color: var(--dl-color-branding-aliceblue);
    border-color: var(--dl-color-branding-yaleblue);
    background-color: var(--dl-color-branding-yaleblue);
  }
  .home-menu {
    display: none;
  }
  .home-container02 {
    display: none;
  }
  .home-burger-menu {
    display: block;
  }
  .home-link01 {
    color: var(--dl-color-gray-white);
    border-color: var(--dl-color-gray-white);
    background-color: transparent;
  }
  .home-hero {
    height: auto;
    padding-left: 0;
    flex-direction: column;
  }
  .home-text009 {
    text-align: center;
  }
  .home-container10 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-image02 {
    width: 80%;
    max-width: 991px;
    align-self: center;
  }
  .home-left {
    top: 1030px;
  }
  .home-right {
    top: 650px;
  }
  .home-container14 {
    padding-left: 0;
    flex-direction: column;
  }
  .home-container15 {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .home-container16 {
    width: 100%;
  }
  .home-container17 {
    width: 100%;
  }
  .home-container18 {
    gap: 3rem;
    flex-direction: column;
  }
  .home-container19 {
    width: 100%;
    height: auto;
  }
  .home-text023 {
    align-self: center;
    text-align: center;
  }
  .home-text029 {
    align-self: center;
  }
  .home-text032 {
    align-self: center;
    text-align: center;
  }
  .home-container20 {
    width: 100%;
  }
  .home-image08 {
    width: 40%;
    height: auto;
  }
  .home-container21 {
    padding-left: 0;
    flex-direction: column;
  }
  .home-container22 {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .home-container23 {
    width: 100%;
  }
  .home-container24 {
    width: 100%;
    justify-content: center;
  }
  .home-container25 {
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .home-menu {
    display: none;
  }
  .home-container02 {
    display: none;
  }
  .home-burger-menu {
    display: block;
  }
  .home-hero {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text009 {
    font-size: 50px;
    text-align: center;
  }
  .home-text013 {
    text-align: center;
  }
  .home-container11 {
    align-items: center;
    flex-direction: column;
  }
  .home-text017 {
    text-align: center;
  }
  .home-image02 {
    width: 90%;
    height: auto;
    max-width: 767px;
  }
  .home-left {
    top: 970px;
  }
  .home-clients {
    justify-content: center;
  }
  .home-image03 {
    margin: var(--dl-space-space-unit);
  }
  .home-image04 {
    margin: var(--dl-space-space-unit);
  }
  .home-image05 {
    margin: var(--dl-space-space-unit);
  }
  .home-image06 {
    margin: var(--dl-space-space-unit);
  }
  .home-image07 {
    margin: var(--dl-space-space-unit);
  }
  .home-text021 {
    text-align: center;
  }
  .home-text022 {
    text-align: center;
  }
  .home-text036 {
    text-align: center;
  }
  .home-text037 {
    text-align: center;
  }
  .home-text038 {
    font-size: 40px;
  }
  .home-container26 {
    display: none;
  }
  .home-container34 {
    flex: 0;
  }
  .home-container49 {
    display: flex;
  }
  .home-container90 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-container91 {
    flex-direction: column;
  }
  .home-text191 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-container01 {
    width: 189px;
  }
  .home-mid {
    align-items: center;
  }
  .home-text004 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text005 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text006 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text007 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text008 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-button:hover {
    color: var(--dl-color-branding-aliceblue) !important;
  }
  .home-bot {
    width: 100%;
  }
  .home-hero {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-container09 {
    position: relative;
  }
  .home-container10 {
    align-self: center;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .home-image02 {
    width: 100%;
    height: auto;
  }
  .home-left {
    top: 730px;
  }
  .home-right {
    display: none;
  }
  .home-text021 {
    font-size: 2.5em;
  }
  .home-text022 {
    font-size: 1.8rem;
  }
  .home-text029 {
    text-align: center;
  }
  .home-text036 {
    font-size: 2.5em;
  }
  .home-text037 {
    font-size: 1.8rem;
  }
  .home-container24 {
    gap: 1rem;
  }
  .home-text038 {
    text-align: center;
  }
  .home-container74 {
    display: none;
  }
  .home-container75 {
    flex-wrap: wrap;
  }
  .home-container82 {
    flex-wrap: wrap;
  }
}
