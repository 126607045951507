.how-to-create-no-img-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 350px;
  align-items: flex-start;
  flex-direction: column;
}
.how-to-create-no-img-container1 {
  width: 100%;
  height: 220px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.how-to-create-no-img-text {
  font-size: 1.45em;
  margin-bottom: var(--dl-space-space-unit);
  color: var(--dl-color-branding-aliceblue);
}
.how-to-create-no-img-text1 {
  font-size: 1.1rem;
  margin-bottom: 0px;
  color: var(--dl-color-branding-aliceblue);
}




@media(max-width: 1600px) {
  .how-to-create-no-img-root-class-name {
    max-width: 300px;
  }
  .how-to-create-no-img-root-class-name1 {
    max-width: 300px;
  }
  .how-to-create-no-img-root-class-name2 {
    max-width: 300px;
  }
  .how-to-create-no-img-root-class-name3 {
    max-width: 300px;
  }
  .how-to-create-no-img-root-class-name4 {
    max-width: 300px;
  }
  .how-to-create-no-img-root-class-name5 {
    max-width: 300px;
  }
}
@media(max-width: 1200px) {
  .how-to-create-no-img-container1 {
    height: auto;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .how-to-create-no-img-text {
    color: var(--dl-color-branding-aliceblue);
  }
  .how-to-create-no-img-text1 {
    color: var(--dl-color-branding-aliceblue);
  }
}
@media(max-width: 991px) {
  .how-to-create-no-img-container1 {
    align-items: center;
    justify-content: flex-start;
  }
  .how-to-create-no-img-text1 {
    text-align: center;
  }
  .how-to-create-no-img-root-class-name {
    max-width: 280px;
  }
  .how-to-create-no-img-root-class-name1 {
    max-width: 280px;
  }
  .how-to-create-no-img-root-class-name2 {
    max-width: 280px;
  }
  .how-to-create-no-img-root-class-name3 {
    max-width: 280px;
  }
  .how-to-create-no-img-root-class-name4 {
    max-width: 280px;
  }
  .how-to-create-no-img-root-class-name5 {
    max-width: 280px;
  }
}
@media(max-width: 767px) {
  .how-to-create-no-img-root-class-name {
    max-width: 100%;
  }
  .how-to-create-no-img-root-class-name1 {
    max-width: 100%;
  }
  .how-to-create-no-img-root-class-name2 {
    max-width: 100%;
  }
  .how-to-create-no-img-root-class-name3 {
    max-width: 100%;
  }
  .how-to-create-no-img-root-class-name4 {
    max-width: 100%;
  }
  .how-to-create-no-img-root-class-name5 {
    max-width: 100%;
  }
}
