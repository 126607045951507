.price-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.price-page-text {
  width: 100%;
  margin-top: var(--dl-space-space-doubleunit);
  text-align: center;
  margin-bottom: 0px;
  font-size: 3.5em;
}
.price-page-container001 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
}
.price-page-container002 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  max-width: 1600px;
  align-items: flex-start;
  flex-direction: column;
}
.price-page-container003 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container004 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-bottom-width: 1px;
}
.price-page-text001 {
  font-size: 24px;
}
.price-page-container005 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-bottom-width: 1px;
}
.price-page-text002 {
  font-size: 24px;
}
.price-page-container006 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-bottom-width: 1px;
}
.price-page-text003 {
  font-size: 24px;
}
.price-page-container007 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-bottom-width: 1px;
}
.price-page-text004 {
  font-size: 24px;
}
.price-page-container008 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-bottom-width: 1px;
}
.price-page-text005 {
  font-size: 24px;
}
.price-page-container009 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container010 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text006 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container011 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text013 {
  font-size: 20px;
}
.price-page-text014 {
  font-style: normal;
  font-weight: 700;
}
.price-page-container012 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text017 {
  font-size: 20px;
}
.price-page-text019 {
  font-style: normal;
  font-weight: 700;
}
.price-page-container013 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text023 {
  font-size: 20px;
}
.price-page-text025 {
  font-style: normal;
  font-weight: 700;
}
.price-page-container014 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-text029 {
  font-size: 20px;
}
.price-page-text031 {
  font-style: normal;
  font-weight: 700;
}
.price-page-container015 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container016 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text035 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container017 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text039 {
  font-size: 20px;
}
.price-page-container018 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text042 {
  font-size: 20px;
}
.price-page-container019 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text045 {
  font-size: 20px;
}
.price-page-container020 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-text048 {
  font-size: 20px;
}
.price-page-container021 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container022 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text051 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container023 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text055 {
  font-size: 20px;
}
.price-page-container024 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text058 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.price-page-container025 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text061 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.price-page-container026 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-text064 {
  font-size: 20px;
}
.price-page-text065 {
  font-style: normal;
  font-weight: 500;
}
.price-page-container027 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container028 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text067 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container029 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container030 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon002 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container031 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon004 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container032 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon006 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container033 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container034 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text071 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container035 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon008 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container036 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon010 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container037 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon012 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container038 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon014 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container039 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container040 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text075 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container041 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon016 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container042 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon018 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container043 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon020 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container044 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon022 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container045 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container046 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text079 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container047 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon024 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container048 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon026 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container049 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon028 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container050 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon030 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container051 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container052 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text083 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container053 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon032 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container054 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon034 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container055 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon036 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container056 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon038 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container057 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container058 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text087 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container059 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon040 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container060 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon042 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container061 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon044 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container062 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon046 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container063 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container064 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text091 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container065 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon048 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container066 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon050 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container067 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon052 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container068 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon054 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container069 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container070 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text095 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container071 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon056 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container072 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon058 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container073 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon060 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container074 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon062 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container075 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container076 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text099 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container077 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon064 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container078 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon066 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container079 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon068 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container080 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon070 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container081 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container082 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text103 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container083 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon072 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container084 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon074 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container085 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon076 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container086 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon078 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container087 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container088 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text107 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container089 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon080 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container090 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text111 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.price-page-container091 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text114 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.price-page-container092 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-text117 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.price-page-container093 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container094 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text120 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container095 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon082 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container096 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon084 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container097 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon086 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container098 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon088 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container099 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container100 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text124 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container101 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon090 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container102 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon092 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container103 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon094 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container104 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon096 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container105 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container106 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text128 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container107 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon098 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container108 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon100 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container109 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon102 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container110 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon104 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container111 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container112 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text132 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container113 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon106 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container114 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon108 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container115 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon110 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container116 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon112 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container117 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container118 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text136 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container119 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon114 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container120 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon116 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container121 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon118 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container122 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon120 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container123 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container124 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text140 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container125 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon122 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container126 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon124 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container127 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon126 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container128 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon128 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container129 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container130 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text144 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container131 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon130 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container132 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon132 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container133 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text148 {
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.price-page-container134 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-text151 {
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.price-page-container135 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container136 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text154 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container137 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon134 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container138 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon136 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container139 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon138 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container140 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon140 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container141 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container142 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text158 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container143 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon142 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container144 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon144 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container145 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon146 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container146 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon148 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container147 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container148 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text162 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container149 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon150 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container150 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon152 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container151 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon154 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container152 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon156 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container153 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container154 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text166 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container155 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon158 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container156 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon160 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container157 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-icon162 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container158 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-icon164 {
  width: 2.3rem;
  height: 2.3rem;
}
.price-page-container159 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container160 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text170 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.price-page-container161 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text177 {
  font-size: 20px;
}
.price-page-text178 {
  font-style: normal;
  font-weight: 700;
}
.price-page-container162 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text181 {
  font-size: 20px;
}
.price-page-text183 {
  font-style: normal;
  font-weight: 700;
}
.price-page-container163 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-text187 {
  font-size: 20px;
}
.price-page-text189 {
  font-style: normal;
  font-weight: 700;
}
.price-page-container164 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-text193 {
  font-size: 20px;
}
.price-page-text195 {
  font-style: normal;
  font-weight: 700;
}
.price-page-container165 {
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  background-color: white;
}
.price-page-container166 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-container167 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-button {
  color: var(--dl-color-branding-aliceblue);
  font-size: 18px;
  transition: 0.3s;
  background-color: var(--dl-color-branding-yaleblue);
}
.price-page-button:hover {
  background-color: var(--dl-color-branding-brightblue);
}
.price-page-container168 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-button1 {
  color: var(--dl-color-branding-aliceblue);
  font-size: 18px;
  transition: 0.3s;
  background-color: var(--dl-color-branding-yaleblue);
}
.price-page-button1:hover {
  background-color: var(--dl-color-branding-brightblue);
}
.price-page-text199 {
  font-size: 18px;
}
.price-page-text200:hover {
  background-color: var(--dl-color-branding-brightblue);
}
.price-page-container169 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.price-page-button2 {
  color: var(--dl-color-branding-aliceblue);
  font-size: 18px;
  transition: 0.3s;
  background-color: var(--dl-color-branding-yaleblue);
}
.price-page-button2:hover {
  background-color: var(--dl-color-branding-brightblue);
}
.price-page-text202 {
  font-size: 18px;
}
.price-page-text203:hover {
  background-color: var(--dl-color-branding-brightblue);
}
.price-page-container170 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-700);
  border-width: 0;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
}
.price-page-button3 {
  color: var(--dl-color-branding-aliceblue);
  font-size: 18px;
  transition: 0.3s;
  background-color: var(--dl-color-branding-yaleblue);
}
.price-page-button3:hover {
  background-color: var(--dl-color-branding-brightblue);
}
.price-page-text205 {
  font-size: 18px;
}
.price-page-text206:hover {
  background-color: var(--dl-color-branding-brightblue);
}
@media(max-width: 991px) {
  .price-page-container002 {
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
  }
  .price-page-container003 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container009 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container015 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container021 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container027 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container033 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container039 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container045 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container051 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container057 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container063 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container069 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container075 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container081 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container087 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container093 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container099 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container105 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container111 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container117 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container123 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container129 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container135 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container141 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container147 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container153 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container159 {
    white-space: normal;
    justify-content: flex-start;
  }
  .price-page-container165 {
    white-space: normal;
    justify-content: flex-start;
  }
}
@media(max-width: 479px) {
  .price-page-text {
    font-size: 3em;
  }
}