.mockup_width {
  max-width: 62%;
}
.new-container {
  background-color: white !important;
  position: relative;
  justify-content: flex-start !important;
  padding: 1rem 0;
}
#laptop_img {
  display: none;
}
.other_laptop_image {
  right: 950px;
}
.features-container {
  width: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.features-text {
  width: 100%;
  font-size: 3.5em;
  align-self: center;
  margin-top: var(--dl-space-space-doubleunit);
  text-align: center;
}
.features-card-types {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 80%;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: flex-start;
}
.features-container01 {
  gap: 2rem;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.features-container02 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-text03 {
  font-size: 3rem;
}
.features-text04 {
  font-size: 2rem;
}
.features-container03 {
  gap: var(--dl-space-space-doubleunit);
  flex: 1;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-container04 {
  gap: 3rem;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.features-change-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.features-change {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 80%;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: flex-start;
}
.features-container05 {
  gap: 2rem;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.features-container06 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-text05 {
  color: var(--dl-color-branding-aliceblue);
  font-size: 3rem;
}
.features-text06 {
  color: var(--dl-color-branding-aliceblue);
  font-size: 2rem;
}
.features-container07 {
  gap: var(--dl-space-space-doubleunit);
  flex: 1;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-container08 {
  gap: 3rem;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.features-push {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 80%;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: flex-start;
}
.features-container09 {
  gap: 2rem;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.features-container10 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-text07 {
  font-size: 3rem;
}
.features-text08 {
  font-size: 2rem;
}
.features-container11 {
  gap: var(--dl-space-space-doubleunit);
  flex: 1;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-container12 {
  gap: 3rem;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.features-interactive-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.features-change1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 80%;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: flex-start;
}
.features-container13 {
  gap: 2rem;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.features-container14 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-text09 {
  color: var(--dl-color-branding-aliceblue);
  font-size: 3rem;
}
.features-text10 {
  color: var(--dl-color-branding-aliceblue);
  font-size: 2rem;
}
.features-container15 {
  gap: var(--dl-space-space-doubleunit);
  flex: 1;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-container16 {
  gap: 3rem;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.features-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.features-card-builder {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 80%;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: flex-start;
}
.features-container18 {
  gap: 2rem;
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.features-container19 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-heading {
  font-size: 3rem;
}
.features-container20 {
  gap: 1.5rem;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
}
.features-advertise-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.features-change2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 80%;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: flex-start;
}
.features-container21 {
  gap: 2rem;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 0px;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.features-container22 {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-text11 {
  color: var(--dl-color-branding-aliceblue);
  font-size: 3rem;
}
.features-container23 {
  gap: var(--dl-space-space-doubleunit);
  flex: 1;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-text12 {
  color: var(--dl-color-branding-aliceblue);
  width: 100%;
  font-size: 2em;
}
.features-container24 {
  gap: 3rem;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.features-text13 {
  color: var(--dl-color-branding-aliceblue);
  width: 100%;
  font-size: 2em;
}
.features-container25 {
  gap: 3rem;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media(max-width: 1600px) {
  .other_laptop_image {
    right: 820px;
  }
  .features-container04 {
    gap: 4rem;
  }
  .features-container08 {
    gap: 4rem;
  }
  .features-container12 {
    gap: 4rem;
  }
  .features-container16 {
    gap: 4rem;
  }
  .features-container24 {
    gap: 4rem;
  }
  .features-container25 {
    gap: 4rem;
  }
  .how-to-create-container {
    max-width: 300px;
  }
  .mockup_width {
    max-width: 80%;
  }
}
@media(max-width: 1200px) {
  .other_laptop_image {
    right: 620px;
  }
  .features-container18 {
    width: 40%;
  }
  .features-card-types {
    max-width: 1110px;
  }
  .features-container01 {
    padding-left: var(--dl-space-space-unit);
  }
  .features-change {
    max-width: 1110px;
  }
  .features-container05 {
    padding-left: var(--dl-space-space-unit);
  }
  .features-push {
    max-width: 1110px;
  }
  .features-container09 {
    padding-left: var(--dl-space-space-unit);
  }
  .features-change1 {
    max-width: 1110px;
  }
  .features-container13 {
    padding-left: var(--dl-space-space-unit);
  }
  .features-card-builder {
    max-width: 1110px;
  }
  .features-container18 {
    padding-left: var(--dl-space-space-unit);
  }
  .features-change2 {
    max-width: 1110px;
  }
  .features-container21 {
    padding-left: var(--dl-space-space-unit);
  }
}
@media(max-width: 991px) {
  .other_laptop_image {
    display: none;
  }
  .laptop_image {
    width: 100%;
  }
  #laptop_img {
    display: block;
  }
  .features-container01 {
    padding-left: 0;
    flex-direction: column;
  }
  .features-container02 {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .features-container03 {
    width: 100%;
  }
  .features-container04 {
    width: 100%;
    justify-content: center;
  }
  .features-container05 {
    padding-left: 0;
    flex-direction: column;
  }
  .features-container06 {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .features-container07 {
    width: 100%;
  }
  .features-container08 {
    width: 100%;
    justify-content: center;
  }
  .features-container09 {
    padding-left: 0;
    flex-direction: column;
  }
  .features-container10 {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .features-container11 {
    width: 100%;
  }
  .features-container12 {
    width: 100%;
    justify-content: center;
  }
  .features-container13 {
    padding-left: 0;
    flex-direction: column;
  }
  .features-container14 {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .features-container15 {
    width: 100%;
  }
  .features-container16 {
    width: 100%;
    justify-content: center;
  }
  .features-container18 {
    width: 100%;
    padding-left: 0;
    flex-direction: column;
  }
  .features-container19 {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .features-container20 {
    justify-content: center;
  }
  .features-container21 {
    padding-left: 0;
    flex-direction: column;
  }
  .features-container22 {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .features-container23 {
    width: 100%;
  }
  .features-text12 {
    text-align: center;
  }
  .features-container24 {
    width: 100%;
  }
  .features-text13 {
    text-align: center;
  }
  .features-container25 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .features-text03 {
    text-align: center;
  }
  .features-text04 {
    font-size: 1.8rem;
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .features-text05 {
    text-align: center;
  }
  .features-text06 {
    font-size: 1.8rem;
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .features-text07 {
    text-align: center;
  }
  .features-text08 {
    font-size: 1.8rem;
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .features-text09 {
    text-align: center;
  }
  .features-text10 {
    font-size: 1.8rem;
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .features-heading {
    text-align: center;
  }
  .features-text11 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .features-text {
    font-size: 3em;
  }
  .features-text03 {
    font-size: 2.3em;
  }
  .features-text04 {
    font-size: 1.5rem;
  }
  .features-text05 {
    font-size: 2.3rem;
  }
  .features-text06 {
    font-size: 1.5rem;
  }
  .features-text07 {
    font-size: 2.3rem;
  }
  .features-text08 {
    font-size: 1.5rem;
  }
  .features-text09 {
    font-size: 2.3rem;
  }
  .features-text10 {
    font-size: 1.5rem;
  }
  .features-heading {
    font-size: 2.3rem;
  }
  .features-text11 {
    font-size: 2.3rem;
  }
}
