.store-banner-store-banner {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.store-banner-link {
  display: contents;
}
.store-banner-image {
  width: 135px;
  object-fit: cover;
  text-decoration: none;
}
.store-banner-link1 {
  display: contents;
}
.store-banner-image1 {
  width: 135px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}

@media(max-width: 991px) {
  .store-banner-root-class-name {
    align-self: flex-start;
  }
}
@media(max-width: 767px) {
  .store-banner-root-class-name {
    align-self: center;
  }
}
@media(max-width: 479px) {
  .store-banner-root-class-name {
    align-self: center;
  }
}
@media(max-width: 414px) {
  .store-banner-root-class-name {
    display: flex;
    flex-direction: column;
  }
  .store-banner-root-class-name img {
    margin: 0;
  }
}
