.contact-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.contact-container2 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-self: stretch;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.contact-image {
  width: 80%;
  height: auto;
  align-self: center;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
}
.contact-container3 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contact-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-text {
  width: 100%;
  font-size: 3.5em;
  align-self: center;
  margin-top: var(--dl-space-space-doubleunit);
  text-align: center;
}
.contact-text03 {
  align-self: center;
  text-align: center;
}
.contact-text12 {
  align-self: center;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.contact-link {
  display: contents;
}
.contact-component1 {
  text-decoration: none;
}
@media(max-width: 1200px) {
  .contact-container1 {
    height: auto;
  }
  .contact-text03 {
    width: auto;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .contact-text12 {
    align-self: center;
    margin-top: var(--dl-space-space-doubleunit);
    text-align: center;
  }
}
@media(max-width: 991px) {
  .contact-container1 {
    flex-direction: column;
  }
  .contact-container2 {
    width: 100%;
    height: 350px;
  }
  .contact-image {
    width: 60%;
    height: 350px;
    object-fit: cover;
    object-position: top;
  }
  .contact-container3 {
    width: 100%;
    align-items: center;
  }
}
@media(max-width: 479px) {
  .contact-image {
    width: 80%;
  }
  .contact-text {
    font-size: 3em;
  }
}
