.roadmap-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.roadmap-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.roadmap-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 90%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.roadmap-container01 {
  gap: 2rem;
  flex: 0 0 auto;
  width: 458px;
  height: 33px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.roadmap-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.roadmap-image {
  width: 158px;
  object-fit: cover;
}
.roadmap-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.roadmap-text {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.roadmap-text:hover {
  color: var(--dl-color-branding-yaleblue);
}
.roadmap-text01 {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.roadmap-text01:hover {
  color: var(--dl-color-branding-yaleblue);
}
.roadmap-text02 {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.roadmap-text02:hover {
  color: var(--dl-color-branding-yaleblue);
}
.roadmap-container02 {
  width: 333px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.roadmap-text03 {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.roadmap-text03:hover {
  color: var(--dl-color-branding-yaleblue);
}
.roadmap-link {
  color: #ffffff;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.roadmap-link:hover {
  color: var(--dl-color-turquoise-default);
}
.roadmap-link1 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  border-color: var(--dl-color-gray-white);
  text-decoration: none;
  background-color: transparent;
}
.roadmap-link1:hover {
  color: var(--dl-color-gray-900);
  background-color: var(--dl-color-gray-white);
}
.roadmap-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.roadmap-menu1 {
  color: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
}
.roadmap-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.roadmap-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.roadmap-logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.roadmap-image1 {
  width: 100px;
  object-fit: cover;
}
.roadmap-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.roadmap-icon03 {
  width: 24px;
  height: 24px;
}
.roadmap-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.roadmap-text04 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.roadmap-text04:hover {
  color: var(--dl-color-turquoise-default);
}
.roadmap-text05 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.roadmap-text05:hover {
  color: var(--dl-color-turquoise-default);
}
.roadmap-text06 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.roadmap-text06:hover {
  color: var(--dl-color-turquoise-default);
}
.roadmap-text07 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.roadmap-text07:hover {
  color: var(--dl-color-turquoise-default);
}
.roadmap-text08 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.roadmap-text08:hover {
  color: var(--dl-color-turquoise-default);
}
.roadmap-bot {
  flex: 0 0 auto;
  display: none;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.roadmap-container03 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.roadmap-link2 {
  display: contents;
}
.roadmap-container04 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.roadmap-icon05 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.roadmap-icon05:hover {
  fill: var(--dl-color-purple-default);
}
.roadmap-link3 {
  display: contents;
}
.roadmap-container05 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.roadmap-icon07 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.roadmap-icon07:hover {
  fill: var(--dl-color-pink-default);
}
.roadmap-link4 {
  display: contents;
}
.roadmap-container06 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.roadmap-icon09 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.roadmap-icon09:hover {
  fill: var(--dl-color-turquoise-default);
}
.roadmap-link5 {
  display: contents;
}
.roadmap-container07 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.roadmap-icon11 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.roadmap-icon11:hover {
  fill: var(--dl-color-orange-default);
}
.roadmap-text09 {
  width: 100%;
  align-self: center;
  margin-top: var(--dl-space-space-doubleunit);
  text-align: center;
  font-size: 3.5em;
}
.roadmap-container08 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.roadmap-container09 {
  margin: auto;
  display: flex;
  grid-gap: 1rem;
  flex-wrap: nowrap;
  max-width: 1600px;
  overflow-x: auto;
  padding-top: var(--dl-space-space-doubleunit);
  white-space: nowrap;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-around;
  scroll-snap-type: x mandatory;
}
.roadmap-container10 {
  width: 32.33%;
  position: relative;
  min-width: 300px;
  white-space: normal;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  background-color: var(--dl-color-branding-aliceblue);
}
.roadmap-text12 {
  width: 100%;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.roadmap-container11 {
  width: 32.33%;
  position: relative;
  min-width: 300px;
  white-space: normal;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  background-color: var(--dl-color-branding-aliceblue);
}
.roadmap-text13 {
  width: 100%;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.roadmap-container12 {
  width: 32.33%;
  position: relative;
  min-width: 300px;
  white-space: normal;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  background-color: var(--dl-color-branding-aliceblue);
}
.roadmap-heading {
  width: 100%;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 1600px) {
  .roadmap-header {
    max-width: 90%;
  }
  .roadmap-container02 {
    width: 313px;
  }
}
@media(max-width: 1200px) {
  .roadmap-header {
    max-width: 1110px;
  }
  .roadmap-container02 {
    width: 313px;
  }
}
@media(max-width: 991px) {
  .roadmap-container02 {
    width: 295px;
  }
  .roadmap-link1 {
    color: var(--dl-color-gray-white);
    border-color: var(--dl-color-gray-white);
    background-color: transparent;
  }
  .roadmap-container09 {
    justify-content: flex-start;
  }
}
@media(max-width: 767px) {
  .roadmap-container {
    overflow-x: hidden;
  }
  .roadmap-menu {
    display: none;
  }
  .roadmap-container02 {
    display: none;
  }
  .roadmap-burger-menu {
    display: block;
  }
  .roadmap-container08 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .roadmap-container10 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    background-color: var(--dl-color-branding-aliceblue);
  }
  .roadmap-container11 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    background-color: var(--dl-color-branding-aliceblue);
  }
  .roadmap-container12 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    background-color: var(--dl-color-branding-aliceblue);
  }
}
@media(max-width: 479px) {
  .roadmap-text {
    font-size: 3em;
  }
  .roadmap-container01 {
    width: 189px;
  }
  .roadmap-mid {
    align-items: center;
  }
  .roadmap-text04 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .roadmap-text05 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .roadmap-text06 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .roadmap-text07 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .roadmap-text08 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .roadmap-button {
    color: var(--dl-color-branding-aliceblue);
    border-color: var(--dl-color-branding-yaleblue);
    background-color: var(--dl-color-branding-yaleblue);
  }
  .roadmap-bot {
    width: 100%;
  }
}
