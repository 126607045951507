.app-links-store-banner {
  width: 300px;
  display: flex;
  align-items: flex-start;
}
.app-links-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.app-links-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  column-gap: var(--dl-space-space-unit);
  align-items: flex-start;
}
.app-links-link {
  display: contents;
}
.app-links-image {
  width: Calc(50% - .5rem);
  object-fit: cover;
  text-decoration: none;
}
.app-links-link1 {
  display: contents;
}
.app-links-image1 {
  width: Calc(50% - .5rem);
  object-fit: cover;
  text-decoration: none;
}
.app-links-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.app-links-link2 {
  color: #e7ecef;
  width: 100%;
  text-align: center;
  transition: 0.3s;
  text-decoration: none;
  background-color: #274c77;
}
.app-links-link2:hover {
  background-color: var(--dl-color-branding-brightblue);
  color: var(--dl-color-branding-aliceblue);
}
.app-links-root-class-name {
  width: 400px;
}
@media(max-width: 991px) {
  .app-links-root-class-name {
    width: 500px;
    align-self: center;
  }
}
@media(max-width: 479px) {
  .app-links-root-class-name {
    width: 100%;
  }
}
