.how-to-create-no-img1-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 350px;
  align-items: flex-start;
  flex-direction: column;
}
.how-to-create-no-img1-container1 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.how-to-create-no-img1-text {
  font-size: 1.45em;
  margin-bottom: var(--dl-space-space-unit);
}
.how-to-create-no-img1-text1 {
  font-size: 1.1rem;
  margin-bottom: 0px;
}




@media(max-width: 1200px) {
  .how-to-create-no-img1-container1 {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .how-to-create-no-img1-text {
    color: var(--dl-color-gray-900);
  }
  .how-to-create-no-img1-text1 {
    color: var(--dl-color-gray-900);
  }
}
@media(max-width: 991px) {
  .how-to-create-no-img1-container1 {
    align-items: center;
    justify-content: flex-start;
  }
  .how-to-create-no-img1-text1 {
    text-align: center;
  }
}
