.card-promotion-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 400px;
  align-items: flex-start;
  flex-direction: column;
}
.card-promotion-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.card-promotion-image {
  width: 272px;
  height: 569px;
  object-fit: cover;
}
.card-promotion-container2 {
  width: 100%;
  height: 220px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.card-promotion-text {
  margin-bottom: var(--dl-space-space-unit);
}
.card-promotion-text1 {
  font-size: 1.3rem;
  margin-bottom: var(--dl-space-space-unit);
}
.card-promotion-button {
  transition: 0.3s;
  background-color: transparent;
}
.card-promotion-button:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-gray-900);
}
.card-promotion-root-class-name {
  max-width: 350px;
}
.card-promotion-root-class-name1 {
  max-width: 350px;
}
.card-promotion-root-class-name2 {
  max-width: 350px;
}
.card-promotion-root-class-name3 {
  max-width: 350px;
}
.card-promotion-root-class-name4 {
  max-width: 350px;
}
.card-promotion-root-class-name5 {
  max-width: 350px;
}
@media(max-width: 1600px) {
  .card-promotion-container1 {
    justify-content: flex-start;
  }
  .card-promotion-root-class-name {
    max-width: 45%;
  }
  .card-promotion-root-class-name1 {
    max-width: 45%;
  }
  .card-promotion-root-class-name2 {
    max-width: 45%;
  }
  .card-promotion-root-class-name3 {
    max-width: 45%;
  }
  .card-promotion-root-class-name4 {
    max-width: 45%;
  }
  .card-promotion-root-class-name5 {
    max-width: 45%;
  }
}
@media(max-width: 1200px) {
  .card-promotion-container1 {
    justify-content: flex-start;
  }
  .card-promotion-container2 {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .card-promotion-root-class-name {
    max-width: 290px;
  }
  .card-promotion-root-class-name1 {
    max-width: 290px;
  }
  .card-promotion-root-class-name2 {
    max-width: 290px;
  }
  .card-promotion-root-class-name3 {
    max-width: 290px;
  }
  .card-promotion-root-class-name4 {
    max-width: 290px;
  }
  .card-promotion-root-class-name5 {
    max-width: 290px;
  }
}
@media(max-width: 991px) {
  .card-promotion-container1 {
    justify-content: center;
  }
  .card-promotion-container2 {
    align-items: center;
    justify-content: flex-start;
  }
  .card-promotion-text1 {
    text-align: center;
  }
  .card-promotion-root-class-name {
    max-width: 45%;
  }
  .card-promotion-root-class-name1 {
    max-width: 45%;
  }
  .card-promotion-root-class-name2 {
    max-width: 45%;
  }
  .card-promotion-root-class-name3 {
    max-width: 45%;
  }
  .card-promotion-root-class-name4 {
    max-width: 45%;
  }
  .card-promotion-root-class-name5 {
    max-width: 45%;
  }
}
@media(max-width: 767px) {
  .card-promotion-container1 {
    justify-content: center;
  }
  .card-promotion-root-class-name {
    max-width: 100%;
  }
  .card-promotion-root-class-name1 {
    max-width: 100%;
  }
  .card-promotion-root-class-name2 {
    max-width: 100%;
  }
  .card-promotion-root-class-name3 {
    max-width: 100%;
  }
  .card-promotion-root-class-name4 {
    max-width: 100%;
  }
  .card-promotion-root-class-name5 {
    max-width: 100%;
  }
}
@media(max-width: 479px) {
  .card-promotion-container1 {
    justify-content: center;
  }
}
